// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$main-color: #418bc9;

.nav-pills-top {
  margin: 10px auto;
}
.nav-pills>li:hover {
  background: $main-color;
  border-radius: 4px;
}
.main-menu.nav-pills>li:hover a {
  color: #ffffff !important;
}
.navbar-nav.nav-pills {
  margin-top: 5px;
}

#students-filter_wrapper .col-sm-7 {
  width: 100% !important;
  .pagination {
    width: 100%;
  }
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  background-color: $main-color;
  color: white;
}
.programTable th,.programTable td.date {
  white-space: nowrap;
}
#students-filter{
  .ssn input {
    max-width: 100px;
  }
  .fname input {
    max-width: 140px;
  }
  .lname input {
    max-width: 140px;
  }
  .initial input {
    max-width: 20px;
  }
}
.form-group.small {
  > div {
    padding: 0px;
  }
  margin-bottom: 10px;
}
.form-group.small.first {
  padding-right:10px;
}
.form-group.small.extra {
  > div {
    padding: 0px 5px;
  }
}
#profile-tabs {
  background: #dddddd;
  li.active>a {
    padding: 12px 15px;
    margin-top: -4px;
  }
}
.table-striped, .programTable {
  tbody>tr:nth-of-type(odd)
  {
    background-color: transparent;
  }
  tbody>tr.selected {
    background-color: $main-color;
    color: #ffffff;
  }
}
.tab-pane {
  margin-top: 15px;
}
@media (min-width: 768px)
{
  .navbar-nav.nav-pills>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 1200px)
{
  .program-loa-group {
    margin-top: 120px;
  }
  .program-total-group {
    margin-top: 113px;
  }
}
.panel-body.daterange {
  padding-right: 0px !important;
}
.panel-body {
  padding: 5px !important;
}
.active-table {
  tr.selected {
    background-color: $main-color;
    color: #ffffff;
  }
  border: none;
}
.statuses-panel, .first-panel, .nopadding {
  padding: 0px;
}
.active-table>tbody>tr>td, .active-table>tbody>tr>th, .active-table>tfoot>tr>td, .active-table>tfoot>tr>th, .active-table>thead>tr>td, .active-table>thead>tr>th {
  border: none;
}
.active-table {
  border: 1px solid #ddd;
}
.active-table tbody tr>th {
  border-right: 1px solid #ddd !important;
}
.reports-container .panel-heading{
  text-align: center;
}
.active-table tr{
  cursor: pointer;
}
.programTable thead {
  background: #dddddd;
}
.loginbox {
  border: 2px solid #e7e7e7;
  border-radius: 4px;
  padding: 10px;
  h1 {
    margin-bottom: 20px;
    color: $main-color;
  }
}
.registerbox h1 {
  margin-bottom: 20px;color: $main-color;
}
.program-loa-group .control-label {
  text-align: left;
}
.navbar-brand.logo {
  padding:0px; margin-right: 15px;
}
.secondary-heading {
  background-color: #dcdcdc;
  padding-top: 13px;
  margin-bottom: 20px;
  padding-bottom: 3px;
  p {
    color:#747474;
    a {
      margin-left: 20px;
    }
  }
}
.navbar.navbar-default {
  margin-bottom: 0px;
}
table#students-filter.dataTable
{
  margin-top: 0px !important;

}
span.profile-icon {
  font-size: 28px;
}
.profile-nav {
  margin-bottom: 10px;
  .btn {
    margin-left: 10px;
  }
}

[v-cloak] {display: none}

.welcome {
  margin-top: 20px;
}

.w3_megamenu .dropdown a,
.w3_megamenu .dropdown-menu  a {
  color:#656565;
}
.w3_megamenu .dropdown-menu > li > a {
  padding:6px 15px;
}
.w3_megamenu .navbar-nav > li > .dropdown-menu {
  margin-top:1px;
}
.w3_megamenu .dropdown-menu {
  box-shadow:none;
  border:1px solid #efefef;
  padding:0;
}
.w3_megamenu .form-control {
  margin-top:10px;
  border:1px solid #efefef;
}
.w3_megamenu .btn {
  margin:10px 0 20px
}
.w3_megamenu video {
  max-width: 100%;
  height: auto;
}
.w3_megamenu iframe,
.w3_megamenu embed,
.w3_megamenu object {
  max-width: 100%;
}
.w3_megamenu .google-map {
  width:100%;
  border:1px solid rgba(255, 255, 255, 0.5);
  min-height:200px;
}
.w3_megamenu div.google-map {
  background:rgba(255, 255, 255, 0.5);
  background: #ffffff;
  height: 200px;
  margin: 0 0 0px 0;
  width: 100%;
}
#googlemaps img{
  max-width:none;
}
.w3_megamenu .dropdown-menu .withoutdesc{
  margin-top:0;
  padding:15px 20px;
  display: block;
  text-align: left;
  text-transform: none;
  width: 100%;
}
.w3_megamenu a:hover {
  text-decoration:none
}
.w3_megamenu .dropdown-menu .withoutdesc ul li {
  padding:3px 10px;
}
.w3_megamenu .dropdown-menu .withoutdesc ul li:hover,
.w3_megamenu .dropdown-menu .withoutdesc ul li:focus{
  color:#262626;
  text-decoration:none;
  background-color:#f5f5f5 !important
}
.w3_megamenu .dropdown-menu .withoutdesc li:last-child {
  border-bottom:0 solid #fff;
}
.w3_megamenu .w3_megamenu-content.withdesc a:after {
  color: #CFCFCF;
  content: attr(data-description);
  display: block;
  font-size: 11px;
  font-weight: 400;
  line-height: 0;
  margin: 10px 0 15px;
  text-transform: uppercase;
}

.w3_megamenu .dropdown-submenu{
  position:relative;
}
.w3_megamenu .dropdown-submenu>.dropdown-menu{
  top:0;
  left:100%;
  margin-top:0;
  margin-left:-1px;
  -webkit-border-radius:0 6px 6px 6px;
  -moz-border-radius:0 6px 6px 6px;
  border-radius:0 6px 6px 6px;
}
.w3_megamenu .dropdown-submenu:hover>.dropdown-menu{
  display:block;
}

.w3_megamenu .dropdown-submenu>a:after{
  display:block;
  content:" ";
  float:right;
  width:0;
  height:0;
  border-color:transparent;
  border-style:solid;
  border-width:5px 0 5px 5px;
  border-left-color:#cccccc;
  margin-top:5px;
  margin-right:-10px;
}
.w3_megamenu .dropdown-submenu:hover>a:after{
  border-left-color:#ffffff;
}
.w3_megamenu .dropdown-submenu.pull-left{
  float:none;
}
.w3_megamenu .dropdown-submenu.pull-left>.dropdown-menu{
  left:-100%;
  margin-left:10px;
  -webkit-border-radius:6px 0 6px 6px;
  -moz-border-radius:6px 0 6px 6px;
  border-radius:6px 0 6px 6px;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover
{
  background-color: transparent;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.notifications-menu {
  &__label {
    position: absolute;
    top: 9px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 3px 4px;
    line-height: .9;
  }
}
.dropdown-notifications-menu {
  width: 280px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  ul.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      a {
        color: #444444;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px;
        display: block;
        white-space: nowrap;
        border-bottom: 1px solid #f4f4f4;
      }
    }
  }
}

.text-aqua {
  color: #00c0ef !important;
}
.notification-details-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin-bottom: 5px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
  }
}
.dropdown-menu {
  .tab-pane {
    width: 500px;
  }
}
.chat
{
  list-style: none;
  margin: 0;
  padding: 0;
}

.chat li
{
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #B3A9A9;
}

.chat li.left .chat-body
{
  margin-left: 60px;
}

.chat li.right .chat-body
{
  margin-right: 60px;
}


.chat li .chat-body p
{
  margin: 0;
  color: #777777;
  .panel-body
  {
    overflow-y: scroll;
    height: 250px;
  }

}

.panel .slidedown .glyphicon, .chat .glyphicon
{
  margin-right: 5px;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}